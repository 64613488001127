/** @jsx jsx */
import { Link } from "gatsby"
import { jsx } from "theme-ui"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"

const NotFoundPage = () => (
  <Layout title="Ops..." newsletter={false}>
    <SEO title="404: Pagina non trovata" />
    <Container sx={{ p: [4, null], textAlign: `center` }}>
      <h1>Pagina non trovata</h1>
      <p>
        Ti sei perso? Torna alla <Link to="/">home</Link>.
      </p>
    </Container>
  </Layout>
)

export default NotFoundPage
